import tw, { theme } from 'twin.macro'
import parse from 'html-react-parser'
import { GatsbyImage } from 'gatsby-plugin-image'

import FlexSection from '@components/FlexSection'
import Title from '@components/Title'

import { findColors, componentLoaded } from '@utils/functions'

import {
  WpPage_Acfpagefields_Content_TwoColumnsTextWithImage,
  WpPrograma_Acfpagefields_Content_TwoColumnsTextWithImage
} from '@_types/graphql-types'
import { useEffect } from 'react'

type contentType =
  | WpPage_Acfpagefields_Content_TwoColumnsTextWithImage
  | WpPrograma_Acfpagefields_Content_TwoColumnsTextWithImage

const TwoColumnsTextWithImage = ({
  data,
  index,
  tabs
}: {
  data: contentType
  index: string
  tabs?: boolean
}) => {
  const { text, title, bgColor, text2, image, invertColumns, tagTitle } = data

  const sectionColors = findColors(bgColor)

  useEffect(() => {
    componentLoaded(title)
  }, [])

  return (
    <FlexSection
      index={index}
      title={!invertColumns ? title : true}
      tagTitle={tagTitle}
      colors={sectionColors}
      alignTitle="left"
      id={title || ''}
      paddingBottom
      paddingTop
      tabs={tabs}
      decorator={[
        {
          color: 'white',
          width: 430,
          x: 5,
          y: 1
        }
      ]}
    >
      <div
        className="flex flex-wrap justify-between"
        css={[invertColumns && tw`flex-row-reverse`]}
      >
        {text && (
          <div tw="lg:max-w-md xl:max-w-xl 2xl:max-w-2xl w-full">
            {invertColumns && title && (
              <Title
                tag="h1"
                title={title}
                color={sectionColors ? sectionColors.text : 'dark'}
              />
            )}
            <div
              tw="prose mb-6!"
              css={[sectionColors.text === 'light' && tw`text-white!`]}
            >
              {text && parse(text)}
            </div>
          </div>
        )}

        <div tw="lg:max-w-md xl:max-w-xl 2xl:max-w-2xl w-full">
          {image && (
            <GatsbyImage
              alt={image.altText}
              image={image.localFile.childImageSharp['mid']}
              tw="rounded-tl-[120px] rounded-br-[120px] mb-4"
            />
          )}
          {text2 && (
            <div
              tw="prose mb-6!"
              css={[sectionColors.text === 'light' && tw`text-white!`]}
            >
              {text2 && parse(text2)}
            </div>
          )}
        </div>
      </div>
    </FlexSection>
  )
}

export default TwoColumnsTextWithImage
